<template>
  <NuxtLoadingIndicator />
  <NuxtPwaManifest />
  <div style="height: 100vh">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup>
import { ofetch } from "ofetch";
globalThis.$fetch = ofetch.create({
  method: "POST",
});
</script>
